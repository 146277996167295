import router from '@/router';

export default class NoticeListViewModel {
  constructor() {
    this.dataList = [];
    this.checkboxOnlyOne = false;
    this.selectedDataList = [];
  }
  init(){
    this.getData();
  }
  onUpdateCheckAll(){
    if(this.checkboxOnlyOne){
      this.selectedDataList = this.dataList
    }else{
      this.selectedDataList = [];
    }
  }
  onUpdateCheckItem(){
    if(this.selectedDataList.length === this.dataList.length && !this.checkboxOnlyOne){
      this.checkboxOnlyOne = true;
    }
    if(this.selectedDataList.length != this.dataList.length && this.checkboxOnlyOne){
      this.checkboxOnlyOne = false;
    }
  }
  onClickRow(rowId){
    router.push({ name: 'APP_NOTICE_DETAIL', params: { id : rowId } });
  }
  getData(){
    this.dataList = [
      { id:'10', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:true },
      { id:'9', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:true },
      { id:'8', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:true },
      { id:'7', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:false },
      { id:'6', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:false },
      { id:'5', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:false },
      { id:'4', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:false },
      { id:'3', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:false },
      { id:'2', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:false },
      { id:'1', title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:false },
    ];
  }
}