<template>
  <PageWithLayout :isBodyBg="false">
  </PageWithLayout>
  <!-- <PageWithLayout>
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">공지사항 목록</strong>
          <p class="desc_tbl">공지사항 내역이 나옵니다</p>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="fourthly"
            text="선택삭제"
            :disabled="viewModel.selectedDataList.length === 0"/>
          <Button
            btnSize="small"
            btnStyle="primary"
            text="등록하기"
            routeName="APP_NOTICE_REGISTER"/>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:55px"/>
        <col style="width:60px"/>
        <col style="width:20%"/>
        <col/>
        <col style="width:90px"/>
      </template>
      <template v-slot:tbl_head>
        <th>
          <CheckboxOnlyOne
            id="checkboxOnlyOne"
            :value.sync="viewModel.checkboxOnlyOne"
            @update:value="viewModel.onUpdateCheckAll()"/>
        </th>
        <th>No.</th>
        <th>제목</th>
        <th>내용</th>
        <th>고정여부</th>
      </template>
      <template v-slot:tbl_body>
        <tr
          v-for="rowData in viewModel.dataList"
          :key="`row_${rowData.id}`"
          :class="['tr_link', { 'tr_selected' : viewModel.selectedDataList.find(selected => selected.id === rowData.id), 'tr_bold' : rowData.isFixed }]"
          @click.prevent="viewModel.onClickRow(rowData.id)">
          <td>
            <CheckboxItem
              :id="rowData"
              :value.sync="viewModel.selectedDataList"
              @update:value="viewModel.onUpdateCheckItem()"/>
          </td>
          <td>{{ rowData.id }}</td>
          <td class="td_ellip">{{ rowData.title }}</td>
          <td class="td_ellip">{{ rowData.content }}</td>
          <td :class="rowData.isFixed ? 'tc_primary' : ''">{{ rowData.isFixed ? '고정' : '미고정' }}</td>
        </tr>
      </template>
    </TableList>
    <Pagination
      :startPageIndex="0"
      :showPageCount="10"
      :selectedPageIndex="0"
      :enabledFirst="false"
      :enabledEnd="true"
      :enabledPrevPage="true"
      :enabledNextPage="true"
      :enabledPrevGroup="true"
      :enabledNextGroup="true"/>
  </PageWithLayout> -->
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import Pagination from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Pagination';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import CheckboxOnlyOne from '@lemontree-ai/lemontree-admin-common-front/components/common/checkbox/CheckboxOnlyOne';
import CheckboxItem from '@lemontree-ai/lemontree-admin-common-front/components/common/checkbox/CheckboxItem';
// viewModel
import NoticeListViewModel from '@/views/landing/notice/viewModel/NoticeListViewModel'

export default {
  name:'NoticeList',
  components:{
    PageWithLayout,
    TableList,
    Pagination,
    Button,
    CheckboxOnlyOne,
    CheckboxItem
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new NoticeListViewModel(),
    }
  },
}
</script>